:root {
  --highlight-color: rgb(234,113,88);
}

.App {
  color: rgb(51,51,51);
  /* background-color: rgb(112,112,112); */
  border-color: rgb(247,247,247);
  outline-color: rgb(167,237,219);
  box-shadow: 0 0 0 rgb(102,102,102);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.progress {
  border-radius: 1rem;
  transition: ease-in-out;
  transition-delay: 2s;
  transition-duration: 24s;
}

.nav-link-span:hover:not(.brand) {
  color: rgb(38, 183, 212);
  transition: 0.5s ease-in color;
}

#project::-webkit-scrollbar {
  display: none;
}

/* rgb(51,51,51) */
/* rgb(112,112,112) */
/* rgb(247,247,247) */
/* rgb(167,237,219) */
/* rgb(102,102,102) */

h1 {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

nav {
  min-height: 75px;
}

#home {
  margin-top: 75px;
  height: calc(100vh - 75px);
  max-width: 700px;
}

#about, #project, #experience {
  padding-top: 75px;
}

#project a,
#about a {
  text-decoration: none;
  font-weight: bolder;
}

#about a:hover {
  text-decoration: underline;
}

#about a {
  color: rgb(234,113,88);
}

#about a.in {
  color: rgb(41,89,181);
}

#home img {
  box-shadow: 0px 0px 10px rgb(102, 102, 102);
}

#contact {
  min-height: 400px;
  max-width: 500px; 
}

#contact a button:hover{
  border-color: rgb(234,113,88);
  background-color: rgb(234,113,88);
}

#footer {
  height: 30vh;
}

#footer a {
  font-size: 24px;
}

.designed-by {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: white;
  padding: 40px 0;

  img {
    margin: 0 5px;
  };
}

@media (min-width: 992px) {
  .width-75 {
    width: 75%;
    margin: 0 auto;
  }
  
  .width-50 {
    width: 50%;
    margin: 0 auto;
  }
}

.experience {
  padding: 1.5rem;

  h4, h6 {
    margin: 0;
  }

  span {
    color: gray;
    font-size: 12px;
    line-height: 12px;
  }

  ul {
    font-size: 14px;
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      padding: 0;
      margin: 8px 0;
    }
    
    li::before {
      content: '➔';
      color: var(--highlight-color);
      margin-right: 5px;
    }
  }
}

.upEffect {
  position: relative;
  animation-name: siri2;
  animation-duration: 2s;
}

.downEffect {
  position: relative;
  animation-name: siri;
  animation-duration: 2s;
}

@keyframes siri {
  0% {
    top: -5vh;
  }
  100% {
    top: 0;
  }
}

@keyframes siri2 {
  0% {
    top: 5vh;
  }
  100% {
    top: 0;
  }
}

.btn.btn-outline-dark:hover {
  background-color: transparent !important;
  color: black;
  cursor: default;
}
